@import "../template/mixins";
@import "../template/variables";

.form-input-icon {
    width: 24px;
    height: 24px;
}

.form-input-text {
    font-size: 12px;
    font-weight: 400;
}

.form-title-border {
    border-bottom: 1px solid $themeSecondaryColor;
}

div.car-select-item-container:hover div.car-select-item {
    outline: 1px solid grey;
}

.small-icon {
    width: 24px;
    height: 24px;
}

.border-dashed {
    border-style: dashed !important;
}

.custom-checkbox {
    width: 24px;
    height: 24px;
}

.payment-button {
    background-color: $themeSecondaryColor;
}

.price-digit {
    letter-spacing: 0.005em;
    font-feature-settings: "tnum" on, "lnum" on;
}

.date-picker-container {
    border: 1px solid #cccccc;
}

.edit-search-icon {
    width: 24px;
    height: 24px;
    & > svg {
        display: flex;
    }
}

.success-check {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    @include mediaQuery(sm) {
        top: 1rem;
        right: 1rem;
    }
}
