.services-page {
    .tab-section {
        background-color: $themeSecondaryColor;
        background-blend-mode: color-dodge;
        // min-height: 440px;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 90% auto;
    }
    .active-tab {
        border-bottom: 2px solid $themePrimaryColor !important;
    }
    .auto-fill-list {
        white-space: nowrap;
        overflow: auto;
        &::-webkit-scrollbar {
            height: 3px;
        }
        &::-webkit-scrollbar-track {
            -webkit-border-radius: auto;
            border-radius: auto;
            background-color: rgb(231, 231, 231);
        }
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: auto;
            border-radius: auto;
            background-color: rgb(143, 143, 143);
        }
    }
    .services-hero {
        background-color: $themeSecondaryColor;
        background-blend-mode: color-dodge;
        width: 100%;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: contain;
        height: auto;
        @include mediaQuery(lg) {
            min-height: 460px;
        }
    }
    button {
        font-size: 14px;
        @include mediaQuery(sm) {
            font-size: 16px;
        }
    }
}
