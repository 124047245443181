@import "../template/variables";

.home-hero {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px;
    &-btn-primary {
        background-image: linear-gradient(to right, $homeHeroPrimaryDark, $homeHeroPrimaryMedium);
        border: 0;
        &:hover {
            background-image: linear-gradient(
                to right,
                $homeHeroPrimaryDark,
                $homeHeroPrimaryMedium
            );
            border: 0;
        }
    }
    &-btn-secondary {
        background-color: $homeHeroSecondaryDark;
        color: $homeHeroPrimaryLight;
        border: 0;
        &:hover {
            background-color: $homeHeroSecondaryDark;
            color: $homeHeroPrimaryLight;
            border: 0;
        }
    }
}
.home-hero-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
}

.credit {
    background-color: #f8f8f8;
    &-numbers {
        padding: 16px 0px;
        background: linear-gradient(270deg, #fae1c3 -36.64%, rgba(250, 225, 195, 0) 97.7%);
    }
    &-title {
        font-size: 20px;
        @include mediaQuery(sm) {
            font-size: 32px;
        }
    }
    &-description {
        font-size: 14px;
        @include mediaQuery(sm) {
            font-size: 20px;
        }
    }
}

.mini-service-icon {
    width: 46px;
    height: 46px;
    @include mediaQuery(sm) {
        width: 55px;
        height: 55px;
    }
}

.mini-service-item {
    margin-bottom: 15px;
    &-first {
        margin-bottom: 15px;
        border-radius: 24px 24px 0px 0px;
        @include mediaQuery(lg) {
            border-radius: 24px 0px 0px 24px;
        }
    }
    &-last {
        border-radius: 0px 0px 24px 24px;
        @include mediaQuery(lg) {
            border-radius: 0px 24px 24px 0px;
        }
    }
}

.transfer-prices {
    padding-bottom: 10px;
    &::-webkit-scrollbar {
        height: 4px;
        background-color: rgb(200, 200, 200);
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track {
        -webkit-border-radius: auto;
        border-radius: auto;
        background: auto;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: auto;
        border-radius: auto;
        border-radius: 50px;
        background-color: #727272;
    }

    &-item {
        @include mediaQuery(sm) {
            width: 392px;
        }
        height: auto;
        background-color: white;
        padding: 20px;
        &-main-image {
            width: 352px;
            min-height: 235px;
            max-height: 235px;
        }
        &-car-image1 {
            width: 138px;
            height: 59px;
        }
    }
}

.transfer-prices-item-main-image {
    max-width: 100%;
    height: auto;
    @include mediaQuery(sm) {
        max-width: unset;
    }
}

.learn-more {
    padding-top: 10rem;
    padding-bottom: 10rem;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include mediaQuery(lg) {
        background-size: 100% auto;
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

.news-letter {
    background-color: $themeSecondaryColor;
    width: 100%;
    height: 320px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    @include mediaQuery(sm) {
        height: 440px;
        background-position: bottom center;
        background-size: 100% auto;
    }
}

.services-icon-small {
    width: 60px;
}

.services-icon-sm-big {
    @include mediaQuery(sm) {
        width: 80px;
    }
}

.py-custom {
    padding-top: 0.85rem !important;
    padding-bottom: 0.85rem !important;
}

.home-hero-title {
    line-height: 35px;
    font-size: 29px;
    @include mediaQuery(sm) {
        line-height: 55px;
        font-size: 48px;
    }
}

.home-hero-description {
    line-height: 25px;
    font-size: 16px;
    @include mediaQuery(sm) {
        line-height: 40px;
        font-size: 22px;
    }
}

.input-email {
    font-size: 12px;
    @include mediaQuery(sm) {
        font-size: 18px;
    }
}
