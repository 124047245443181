.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-800 {
    font-weight: 800 !important;
}

.px-side {
    padding-left: 7%;
    padding-right: 7%;
}

.mx-side {
    margin-left: 7%;
    margin-right: 7%;
}

@for $value from 1 to 96 {
    .fs-#{$value}px {
        font-size: #{$value}px !important;
    }
}

@for $value from 1 to 96 {
    .lh-#{$value}px {
        line-height: #{$value}px !important;
    }
}

@for $value from 1 to 500 {
    .lh-#{$value}pr {
        line-height: percentage($number: $value/100) !important;
    }
}

@for $value from 0 to 100 {
    .w-#{$value} {
        width: percentage($number: $value/100) !important;
    }
}

.bg-dark-gray {
    background-color: #282c32 !important;
}

.text-secondary {
    color: $themeSecondaryColor !important;
}

.text-primary {
    color: $themePrimaryColor !important;
}

.text-gray {
    color: $themeGrayColor;
}

.bg-gray {
    background-color: #e5e5e5 !important;
}

.bg-light-gray {
    background-color: #f8f8f8;
}

.bg-lg-light-gray {
    @include mediaQuery(lg) {
        background: #f8f8f8 !important;
    }
}

.bg-secondary {
    background-color: $themeSecondaryColor;
}

.text-justify {
    text-align: justify !important;
}

.h-lg-100 {
    @include mediaQuery(lg) {
        height: 100%;
    }
}

.ls-5p {
    letter-spacing: 0.005em !important;
}

.ls-m5p {
    letter-spacing: -0.005em !important;
}

.z-999 {
    z-index: 999 !important;
}

.text-sentence {
    &::first-letter {
        text-transform: uppercase;
    }
}

.icon-error {
    width: 30px;
    height: auto;
}

.rotate-90deg {
    transform: rotate(90deg) !important;
}

.rotate-sm-0 {
    @include mediaQuery(sm) {
        transform: rotate(0deg) !important;
    }
}

.cursor-auto {
    cursor: auto !important;
}

.featured-page {
    padding: 3%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center -50px;

    @include mediaQuery(md) {
        background-position: center -110px;
        padding: 6%;
    }
}

.scroll-mt {
    scroll-margin-top: 100px;
    @include mediaQuery(lg) {
        scroll-margin-top: 190px;
    }
}

.location-icon {
    width: 20px;
    height: auto;
    & > svg {
        display: flex;
    }
}

.linear-text {
    font-size: 12px;
    width: 100%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
}

.cursor-pointer {
    cursor: pointer !important;
}

.py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
