.text-dark-gray {
    color: rgb(138, 141, 144);
}

.about-text {
    line-height: 32px;
    font-size: 16px;
    @include mediaQuery(md) {
        line-height: 44px;
        font-size: 22px;
    }
}

.pb-custom {
    padding-bottom: 40% !important;
    @include mediaQuery(lg) {
        padding-bottom: 18% !important;
    }
}

.bg-about-us-hero {
    background-image: url("../../images/bg-about-us-right.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    height: auto;
    @include mediaQuery(lg) {
        background-image: url("../../images/bg-about-us-left.svg"),
            url("../../images/bg-about-us-right.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top left, top right;
        background-size: contain, contain;
    }
}

.bg-about-us {
    background-color: white;
    @include mediaQuery(sm) {
        background-color: #f8f8f8;
    }
}

.about-us-hero {
    position: relative;
    bottom: 35vw;
    @include mediaQuery(lg) {
        bottom: 21vw;
    }
}

.mt-n {
    margin-top: -20vw !important;
    @include mediaQuery(lg) {
        margin-top: -10vw !important;
    }
}

.credit-title {
    font-size: 20px;
    @include mediaQuery(sm) {
        font-size: 32px;
    }
    @include mediaQuery(lg) {
        font-size: 48px;
    }
}

.credit-value {
    font-size: 28px;
    color: black;
    @include mediaQuery(lg) {
        font-size: 72px;
    }
}

.credit-value-container {
    padding-bottom: 1rem;
    @include mediaQuery(sm) {
        padding-bottom: 4rem;
    }
    @include mediaQuery(lg) {
        padding-bottom: 6rem;
    }
}

.credit-bg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
