* {
    font-family: "Montserrat";
    letter-spacing: 0.0168em !important;
}

a {
    text-decoration: none;
    color: unset;
    &:hover {
        color: unset;
    }
}

body {
    margin: 0px;
    padding: 0px;
    background-color: $bodyBackgroudColor;
}

.btn-secondary {
    border: none;
    border-radius: 0px;
    color: $themePrimaryColor;
    background-color: $themeSecondaryColor !important;
    &:hover {
        color: white;
        background-color: lighten($themeSecondaryColor, 5) !important;
        // background-color: yellow;
    }
}
.btn-primary {
    border: none !important;
    border-radius: 0px;

    color: $themeSecondaryColor;
    background-color: $themePrimaryColor !important;
    &:hover {
        color: $themeSecondaryColor;
        background: lighten($themePrimaryColor, 2) !important;
    }
}
.btn-outline-primary {
    border-color: $themePrimaryColor;
    border-radius: 0px;

    color: $themePrimaryColor;
    font-weight: 500;
    background-color: transparent !important;
    &:hover {
        color: $themeSecondaryColor;
        background: $themePrimaryColor !important;
        border-color: $themePrimaryColor;
    }
}

.bg-secondary {
    background-color: $themeSecondaryColor !important;
}

.bg-primary {
    background-color: $themePrimaryColor !important;
}

.text-primary {
    color: $themePrimaryColor !important;
}

.border-primary {
    border-color: $themePrimaryColor !important;
}

.border-secondary {
    border-color: $themeSecondaryColor !important;
}

button,
a {
    box-shadow: none !important;
    &:focus {
        box-shadow: none !important;
    }
}

input.form-control {
    &:focus {
        box-shadow: none !important;
        border-color: #ced4da !important;
    }
}

input.form-check-input {
    &:focus {
        box-shadow: none;
    }
}

textarea.form-control:focus {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
}

input.form-check-input {
    border-radius: 0px !important;
}

// React Select Reset Styles
.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-b62m3t-container {
    height: 100% !important;
}

.css-1s2u09g-control {
    height: 100% !important;
    border-radius: 0px !important;
    padding: 7px !important;
    -webkit-transition: none;
    transition: none;
}

.css-14el2xx-placeholder {
    font-size: 14px !important;
    font-weight: 800 !important;
    font-style: normal !important;
    color: #161b21 !important;
    letter-spacing: 0.005em !important;
    font-feature-settings: "tnum" on, "lnum" on !important;
}

.css-1pahdxg-control {
    border-radius: 0px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    color: #161b21 !important;
    -webkit-transition: none !important;
    transition: none !important;
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none !important;
}

.css-2613qy-menu {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.accordion-button {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.accordion-item:first-of-type,
.accordion-button {
    border-radius: 0 !important;
}

.accordion-item:not(:first-of-type) {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-collapse {
    border-top: 1px solid $themeSecondaryColor !important;
}

.accordion-button:not(.collapsed),
.accordion-body {
    color: $themePrimaryColor !important;
    background-color: $themeSecondaryColor !important;
    &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FAE1C3%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    }
}

.accordion-button::after {
    background-image: url("../../images/icons/add.svg") !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../images/icons//minus.svg") !important;
}

.accordion-body {
    color: white !important;
    font-size: 14px;
    line-height: 32px;
    font-weight: lighter !important;
    text-align: justify;
}

.rdp {
    margin: 0 !important;
}

.rdp-caption_label {
    font-size: 18px !important;
}

:root {
    --rdp-cell-size: 7vw;
    @include mediaQuery(sm) {
        --rdp-cell-size: 5vw;
    }
    @include mediaQuery(md) {
        --rdp-cell-size: 3.7vw;
    }
    @include mediaQuery(lg) {
        --rdp-cell-size: 3.5vw;
    }
}

.MuiTextField-root {
    flex: 1 1 auto !important;
}

.MuiInput-root {
    height: 100% !important;
    border: 1px solid #ced4da !important;
    border-left: 0px !important;
    font-size: 12px !important;
    font-weight: normal;
    color: #212529 !important;
}

.MuiInput-underline:before {
    border-bottom: 0px !important;
}

.MuiInputBase-input {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
    padding-left: 0px !important;
}

label + .MuiInput-formControl {
    margin-top: 0px !important;
}

.MuiInputLabel-formControl {
    transform: none !important;
    top: 1.15rem !important;
    font-size: 12px !important;
    font-weight: normal;
    color: $themeGrayColor !important;
}

.MuiInputLabel-shrink {
    transform: none !important;
}

.MuiInput-underline:after {
    border-bottom: 0px !important;
}

.MuiInputLabel-animated {
    transition: none !important;
}
.dropdown-toggle {
    border-color: #cccccc !important;
    cursor: default !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.btn-outline-secondary:hover {
    color: black;
    background-color: white !important;
    border-color: #6c757d;
}

input.custom-placeholder::placeholder {
    font-size: 14px !important;
    font-weight: 800 !important;
    font-style: normal !important;
    color: #161b21 !important;
    letter-spacing: 0.005em !important;
    font-feature-settings: "tnum" on, "lnum" on !important;
}

label.MuiFormLabel-root {
    z-index: 0 !important;
}

.MuiInputBase-root {
    border-radius: 0px !important;
}

// .css-hdw1oc {
//     display: none !important;
// }

// .css-1in441m {
//     visibility: hidden !important;
// }

.MuiOutlinedInput-notchedOutline {
    top: 0px !important;
}

.MuiFormControl-root {
    width: 100% !important;
    border: 0px !important;
}

input.MuiInputBase::placeholder {
    color: white !important;
}

.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #cccccc !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
    display: none !important;
    border: none !important;
}

.date-picker.MuiInputBase-input {
    padding-top: 0.85rem !important;
    padding-bottom: 0.85rem !important;
}

.date-picker > label.MuiFormLabel-root {
    padding-left: 1rem !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    color: #161b21 !important;
}

.date-picker label.Mui-focused {
    display: block !important;
    width: 100% !important;
    color: #161b21 !important;
    top: 0.85rem !important;
    padding-left: 16px !important;
    padding-right: 10px !important;
}

.MuiFormLabel-root {
    line-height: 1 !important;
}

.MuiInputBase-root {
    font-size: 14px !important;
    font-weight: 400 !important;
}

input.MuiInputBase-input.MuiOutlinedInput-input {
    z-index: 10 !important;
    padding-left: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #161b21 !important;
    font-family: "Montserrat" !important;
    letter-spacing: 0.0168em !important;
    cursor: pointer !important;
}

.time-picker input {
    cursor: pointer !important;
}

label.MuiFormLabel-root.MuiInputLabel-outlined {
    width: 100% !important;
    max-width: none !important;
    padding-left: 16px !important;
    padding-right: 10px !important;
    transform: none !important;
    top: 0.85rem !important;
}

.css-26l3qy-menu {
    z-index: 10000 !important;
}

// .MuiTypography-root {
//     display: none !important;
// }

span.MuiTypography-root.MuiTypography-overline {
    display: none !important;
}

.MuiPickersToolbar-penIconButton {
    top: 0px !important;
}

.css-mvmu1r .Mui-disabled {
    color: #d3d4d3 !important;
}

.extra-items-select__control,
.extra-items-select__menu {
    border-radius: 0 !important;
}

.extra-items-select__control {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.extra-items-select__menu-list {
    display: flex !important;
    gap: 4px !important;
    flex-wrap: wrap !important;
    padding: 6px 10px !important;
}

.extra-items-select__option {
    width: fit-content !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    padding: 6px !important;
    background-color: lightgray !important;
}

.extra-items-select__placeholder {
    font-weight: 400 !important;
    font-size: 12 !important;
    color: gray !important;
}

.extra-items-select__control--is-disabled {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.react-datepicker__input-container {
    padding-right: 10px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border: 1px solid #cccccc !important;
    cursor: pointer !important;
}

.react-datepicker__close-icon {
    padding-right: 12px !important;
}

.react-datepicker__close-icon::after {
    background-color: $themePrimaryColor !important;
    color: black !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.react-datepicker__header {
    background-color: $themePrimaryColor !important;
}
