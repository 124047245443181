.payment-card {
    width: 100%;
    @include mediaQuery(sm) {
        max-width: 520px;
    }
    @include mediaQuery(md) {
        max-width: 600px;
    }
}

.payment-card-title {
    height: 225px;
    background-repeat: no-repeat;
    background-position: top center;
}

.payment-card-description {
    padding: 0 1.5rem;
    @include mediaQuery(sm) {
        padding: 0 3rem;
    }
    @include mediaQuery(md) {
        padding: 0 5rem;
    }
}

.payment-status-icon {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.copy-to-clipboard-alert {
    height: 30px;
    transition: opacity 0.25s ease;
}
