.header {
    .active {
        border-bottom: 2px solid $themePrimaryColor;
        font-weight: bold !important;
    }
    .header-menu-mobile {
        background-color: white;
        height: 100vh;
        width: 70vw;
        margin: 0px;
        position: fixed;
        padding: 1.9rem 1rem;
        padding-right: 1.5rem;
        z-index: 999;
        top: 0px !important;
        @include mediaQuery(lg) {
            display: none;
        }
    }

    .header-menu-mobile-gap {
        background-color: transparent !important;
        height: 100vh;
        width: 27vw;
        margin: 0px;
        position: fixed;
        padding: 1.9rem 1rem;
        padding-right: 1.5rem;
        right: 0px;
        top: 0px;
        z-index: 999;
        @include mediaQuery(lg) {
            display: none;
        }
    }

    $menuToggleAnimationDuration: 200ms;
    .menu-open-animation {
        animation: header-menu-open $menuToggleAnimationDuration;
    }
    .menu-close-animation {
        animation: header-menu-close $menuToggleAnimationDuration;
    }

    @keyframes header-menu-open {
        from {
            left: -100%;
        }
        to {
            left: 0%;
        }
    }

    @keyframes header-menu-close {
        from {
            left: 0%;
        }
        to {
            left: -100%;
        }
    }

    .cross-icon {
        width: 30px;
        height: 30px;
    }
}

.lang-switch {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    & > div {
        background-color: #dfe0df;
    }
    & > div > button {
        padding: 6px 8px !important;
        outline: none;
        font-weight: 500;
        border: none;
    }
    & > div:first-child {
        border-radius: 12px 0px 0px 12px;
        border: 3px solid #dfe0df;
        border-right: none;
        button {
            border-radius: 8px 0px 0px 8px;
        }
    }

    & > div:last-child {
        border-radius: 0px 12px 12px 0px;
        border: 3px solid #dfe0df;
        border-left: none;
        button {
            border-radius: 0px 8px 8px 0px;
        }
    }

    .active-lang {
        background-color: white !important;
        color: black !important;
    }
}

.text-contact-us {
    font-size: 20px;
    font-weight: 600;
    background: -webkit-linear-gradient(270deg, $homeHeroPrimaryMedium, $homeHeroPrimaryDark);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline !important;
}
