.history-hero {
    height: 500px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px;
    @include mediaQuery(lg) {
        height: 760px;
    }
}

.history-hero-overlay {
    width: 100%;
    height: 100%;
}

.history-hero-title {
    font-size: 40px;
    @include mediaQuery(sm) {
        font-size: 48px;
    }
}

.history-hero-description {
    line-height: normal;
    text-align: start;
    font-size: 18px;
    @include mediaQuery(sm) {
        line-height: 40px;
        text-align: justify;
        font-size: 22px;
    }
}

.tour-name {
    letter-spacing: 0.0075em !important;
}

.overlay-button {
    z-index: 2;
    position: absolute;
    bottom: 4rem;
    left: 0;
    background: none;
    border: none;
}

.tour-image {
    filter: brightness(0.7);
}
