$bodyBackgroudColor: #f8f8f8;
$themePrimaryColor: #fae1c3;
$themeSecondaryColor: #161b21;
$themeGrayColor: rgba(22, 27, 33, 0.5);
$footerBackgroundColor: #23282e;

$homeHeroPrimaryDark: #7e5936;
$homeHeroPrimaryMedium: #f5cb86;
$homeHeroPrimaryLight: #fbeacf;

$homeHeroSecondaryDarker: #000000;
$homeHeroSecondaryDark: #171b1f;
$homeHeroSecondaryMedium: #323232;
$homeHeroSecondaryLight: #61625d;

$mediaQueryBreakPoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);
