@import "../template/mixins";
@import "../template/variables";

.driver-information {
    .image {
        width: 100px;
        height: 65px;
        border-radius: 20px;
    }
    .phone {
        width: 26px;
        height: auto;
    }
    .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #b2acfb;
        filter: drop-shadow(0px 0px 5px #b2acfb);
    }
    .border-sm-bottom-0 {
        @include mediaQuery(sm) {
            border-bottom: 0 !important;
        }
    }
    .border-sm-end {
        @include mediaQuery(sm) {
            border-right: 1px solid #dee2e6 !important;
        }
    }
}

.order-information {
    .card {
        background-color: darken(#f8f9fa, 2);
        border-color: darken(#f8f9fa, 4);
    }
    .scroll-hidden::-webkit-scrollbar {
        display: none;
    }
}

.tracking-map {
    border: 1px solid darken(#f8f9fa, 4);
}

.map-item {
    width: 100%;
    height: 500px;

    @include mediaQuery(lg) {
        height: 100%;
    }
}
