.alert {
    position: fixed;
    bottom: 0.5rem;
    width: 100%;
}

.alert-icon-container {
    width: 40px;
}

.content-container {
    height: 48px;
    margin-left: -3px;
}

.transition-fade {
    transition: opacity 0.25s ease;
}

.transition-roll {
    transition: max-width 0.4s ease;
}

.rounded-10px {
    border-radius: 10px !important;
}

.rounded-top-right-5px {
    border-top-right-radius: 5px !important;
}

.rounded-bottom-right-5px {
    border-bottom-right-radius: 5px !important;
}

.rounded-5px {
    border-radius: 5px !important;
}

.rounded-top-left-5px {
    border-top-left-radius: 5px !important;
}

.rounded-bottom-left-5px {
    border-bottom-left-radius: 5px !important;
}

.mw-1000px {
    max-width: 1000px !important;
}

.mw-0px {
    max-width: 0px !important;
}
