.not-found-page {
    height: 950px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px;
    @include mediaQuery(lg) {
        height: 650px;
        background-size: 100% auto;
        background-position: 0px -160px;
    }
    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        color: white;
    }
    .title {
        line-height: 200px;
        font-size: 120px;
        @include mediaQuery(lg) {
            font-size: 200px;
        }
    }
}
