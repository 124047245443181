.bg-footer {
    background-color: $footerBackgroundColor;
    background-repeat: no-repeat;
    background-position-x: 60%;
    background-position-y: 40%;
    @include mediaQuery(sm) {
        background-positio-x: 0%;
        background-position-y: 25%;
    }
    @include mediaQuery(md) {
        background-size: contain;
        background-position-y: 0%;
        background-position-x: 10%;
    }
}

.border-bottom-thick {
    border-bottom: 4px solid $themePrimaryColor;
}

.border-bottom-thin {
    border-bottom: 2px solid $themePrimaryColor;
}

.footer-text {
    letter-spacing: 0.0168em;
    font-feature-settings: "tnum" on, "lnum" on;
    line-height: 150%;
    @include mediaQuery(sm) {
        line-height: 200%;
    }
}

.footer-link {
    margin: 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-feature-settings: "tnum" on, "lnum" on;
}
