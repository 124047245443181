button#search-people {
    height: 100% !important;
}

.hover-border-gray:hover {
    border-color: grey !important;
}

.p2p-arrow {
    width: 24px;
    height: auto;
    transform: rotate(90deg);
    @include mediaQuery(sm) {
        transform: rotate(0deg);
    }
}
